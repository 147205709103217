<template>
  <div>
    <form @submit.prevent="updateAvatar" class="uk-margin-bottom-medium">
      <div>
        <img :src="avatar" :alt="user.name" style="height: 100px" />
        <a href="#" class="uk-margin-medium-left uk-text-dark" @click.prevent="selectAvatar"
          >Change</a
        >
        <input
          type="file"
          style="display: none"
          id="select-avatar"
          ref="selected_avatar"
          @change="uploadAvatar"
        />
      </div>
    </form>
    <form @submit.prevent="submit">
      <div class="uk-grid uk-margin-medium-top">
        <div class="uk-width-1-2 uk-margin-small">
          <p>Email: {{ user.email }}</p>
        </div>
        <div class="uk-width-1-2 uk-margin-small">
          <p>Joined: {{ user.joined }}</p>
        </div>
        <div class="uk-width-1-2 uk-margin-small">
          <p>Participation: {{ user.comments }}</p>
        </div>
        <div class="uk-width-1-2 uk-margin-small">
          <p>Level: {{ user.level }}</p>
        </div>
        <div class="uk-width-1-2 uk-margin-small">
          <div class="form-group">
            <label>Name</label>
            <input
              type="text"
              v-model="user.name"
              placeholder="Name"
              required
              class="uk-input"
            />
          </div>
        </div>
        <div class="uk-width-1-2 uk-margin-small">
          <div class="form-group">
            <label>Phone Number</label>
            <input
              type="text"
              v-model="user.phone_number"
              placeholder="Phone Number"
              required
              class="uk-input"
            />
          </div>
        </div>
        <div class="uk-width-1-2 uk-margin-small">
          <div class="form-group">
            <label>Country</label>
            <input
              type="text"
              v-model="user.country"
              placeholder="Country"
              required
              class="uk-input"
            />
          </div>
        </div>
        <div class="uk-width-1-2 uk-margin-small">
          <div class="form-group">
            <label>Telegram Username</label>
            <input
              type="text"
              v-model="user.telegram_username"
              placeholder="Telegram Username"
              required
              class="uk-input"
            />
          </div>
        </div>
        <div class="uk-width-1-1 uk-margin-small">
          <div class="form-group">
            <label>Bio</label>
            <textarea
              type="text"
              v-model="user.bio"
              placeholder="Bio"
              required
              class="uk-textarea"
              rows="4"
            ></textarea>
          </div>
        </div>
        <div class="uk-width-1-1">
          <button class="uk-button uk-button-primary">Update</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: this.$store.getters.getAuthUser,
    }
  },

  methods: {
    selectAvatar() {
      document.getElementById('select-avatar').click()
    },

    uploadAvatar() {
      let data = new FormData
      data.append('avatar', this.$refs.selected_avatar.files[0])

      this.$axios
        .post("/api/v1/guest/my-account/avatar", data, {
          headers: { "content-type": "multipart/form-data" },
        }).then(response => {
          this.$store.dispatch('setAuthUser', response.data.user)
        })
    },

    submit() {
      let data = {
        name: this.user.name,
        phone_number: this.user.phone_number,
        country: this.user.country,
        bio: this.user.bio,
        telegram_username: this.user.telegram_username,
      }

      this.$axios.post('/api/v1/guest/my-account/profile', data).then(() => {
        this.$toasted.show('Your account has been updated', { duration: 5000 })
      })
    }
  },

  computed: {
    avatar() {
      return this.$store.getters.getAuthUser.avatar
    }
  }
};
</script>
