<template>
  <div class="uk-container uk-margin-medium-top">
    <h2>My Account</h2>
    <div class="uk-grid-large uk-grid uk-grid-stack">
      <div class="uk-width-2-3@m">
        <div class="uk-card uk-card-default uk-card-body">
          <ul class="uk-tab">
            <li
              :class="$route.name == 'guest.my-account' ? 'uk-active' : ''"
              :aria-expanded="$route.name == 'guest.my-account'"
            >
              <router-link :to="{ name: 'guest.my-account' }"
                >Profile</router-link
              >
            </li>
            <li
              :aria-expanded="$route.name == 'guest.my-account.security'"
              :class="
                $route.name == 'guest.my-account.security' ? 'uk-active' : ''
              "
            >
              <router-link
                :class="`nav-link ${
                  $route.name == 'guest.my-account.security' ? 'active' : ''
                }`"
                :to="{ name: 'guest.my-account.security' }"
                >Security</router-link
              >
            </li>
            <li
              :aria-expanded="$route.name == 'guest.my-account.affiliate'"
              :class="
                $route.name == 'guest.my-account.affiliate' ? 'uk-active' : ''
              "
            >
              <router-link
                :class="`nav-link ${
                  $route.name == 'guest.my-account.affiliate' ||
                  $route.name == 'guest.my-account.extend'
                    ? 'active'
                    : ''
                }`"
                :to="{ name: 'guest.my-account.affiliate' }"
                >Affiliate Program</router-link
              >
            </li>
            <li
              class="uk-tab-responsive uk-active uk-hidden"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <a>Actions</a>
              <div class="uk-dropdown uk-dropdown-small" aria-hidden="true">
                <ul class="uk-nav uk-nav-dropdown"></ul>
                <div></div>
              </div>
            </li>
          </ul>

          <ul id="my-profile-content" class="uk-switcher uk-margin">
            <li class="uk-active" aria-hidden="false">
              <router-view />
            </li>
          </ul>
        </div>
      </div>
      <div class="uk-width-1-3@m">
        <div class="uk-card uk-card-default uk-padding-small">
          <h5>My Affiliate Link</h5>
          <p class="uk-margin-small-top" v-html="user.referral_code" style="overflow-wrap: break-word;"></p>
          <p class="uk-margin-small-top uk-margin-remove-bottom">
            <social-share :url="user.referral_code" />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    user() {
      return this.$store.getters.getAuthUser;
    },
  },
};
</script>
